body, #root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  font-family: 'Open Sans', sans-serif;
}

body {
  position: relative;
  background-size: cover;
  background-image: url("/img/background.png");
  background-color: #6D7697;
}

body:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 174px;
  height: 182px;
  margin-left: -87px;
  margin-top: -91px;
  opacity: 0.2;
  background: url("/img/logo.svg");
}

.Twilio-MainHeader-default { text-transform: uppercase; }
.Twilio-MainHeader-default svg { display: none; }

.chat-block { 
  background-color: #fff; 
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;

  position: fixed !important;
  max-height: 100%;
  max-width: 100%;
  height: 504px;
  width: 276px;
  overflow: auto;
  bottom: 14px;
  right: 20px;
  z-index: 101;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.4);
}

.chat-init {
  position: fixed !important;
  bottom: 14px;
  right: 20px;
  z-index: 102;
}

.chat-header {
  align-items: center;
  cursor: move;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  background: #00877c;
  color: #FFFFFF;
  border-color: #C6CAD7;
  text-transform: uppercase;
  padding: 12px;
}

.chat-content {
  text-align: center; 
  padding: 60px 10px;
  font-size: 14px;
}

.second-text { padding-top: 30px;}
.second-text strong { font-weight: bold; font-size: 20px;}

.chat-button-block { text-align: center; }
.chat-button {
  background: #00877c;
  color: #FFFFFF;
  border-radius: 0px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  white-space: nowrap;
  z-index: 100;
  cursor: pointer;
  max-width: 400px;
  display: inline-block;
  padding: 4px 12px;
  text-transform: uppercase;
  font-size: 11px;
}
.chat-button span {
  position: relative; 
  top: -7px;
}
.chat-button svg {
  width: 24px;
  height: 24px;
}